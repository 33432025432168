<template>
  <div class="sub-menu">
    <div class="nav-bar">
      <div class="menu-links">
        <div
          class="menu-link"
          v-if="checkPermission('Gestion des AAF régie Th')"
        >
          <router-link class="menu-item" to="/regies-th/gestion-aaf">
            Gestion des AAF
          </router-link>
        </div>
        <div class="menu-link" v-if="checkPermission('Suivi des régies Th')">
          <router-link class="menu-item" to="/regies-th/suivi-regie-th">
            Suivi de paiement des régies
          </router-link>
        </div>
        <div
          class="menu-link"
          v-if="checkPermission('Récapitulatif régie par filiale Th')"
        >
          <router-link class="menu-item" to="/regies-th/recap-regie-th">
            Récapitulatif régie par filiale
          </router-link>
        </div>
      </div>
    </div>
    <div class="body">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['getUserData', 'checkPermission'])
  }
};
</script>

<style scoped lang="scss"></style>
